import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios"

const Component = () => {
    const { couponCode } = useParams();


    function getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
            os = 'Mac';
        } else {
            os = 'not able to detect platoform'
        }

        return os;
    }

    function generateRandomNumber (){
        const min = 10000000000000; // Smallest 14-digit number (10^13)
        const max = 99999999999999; // Largest 14-digit number (10^14 - 1)

        // Generate a random number between min and max (inclusive)
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        return randomNumber.toString();
    }

    useEffect(() => {
        // Generate a unique device identifier
        function generateDeviceId() {
            // Use a combination of available device information to create the identifier
            var deviceInfo = [
                navigator.userAgent,
                navigator.platform,
                navigator.vendor,
                window.opera
            ].join('|');
            // Hash the device information to create a unique identifier
            var hash = 0;
            for (var i = 0; i < deviceInfo.length; i++) {
                hash = ((hash << 5) - hash) + deviceInfo.charCodeAt(i);
                hash |= 0; // Convert to 32-bit integer
            }
            const randomDigit = generateRandomNumber()
            return hash.toString() + randomDigit;
        }

        async function redirectToAppStore() {
            try {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                // Check if a unique identifier exists in localStorage
                var deviceId = localStorage.getItem('babycode_device_id');
                if (!deviceId) {
                    // Generate a new unique device identifier and store it in localStorage
                    deviceId = generateDeviceId();
                    localStorage.setItem('babycode_device_id', deviceId);
                }

                console.log(userAgent, ' start detecting')
                // Append the device ID as a query parameter to the App Store URL
                var appStoreUrl = '';
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    console.log(userAgent, ' first if working')
                    appStoreUrl = 'https://apps.apple.com/us/app/babycode-ielts-preparation/id1569449136';
                } else if (/android/i.test(userAgent)) {
                    // appStoreUrl = 'https://babycode.org';
                    appStoreUrl = 'market://details?id=babycode.ielts&referrer=utm_source%3Dyoutube%26utm_medium%3Dvideo_description';
                } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
                    console.log(userAgent, ' second else if working')
                    // appStoreUrl = 'macappstores://apps.apple.com/app/babycode-ielts-preparation/id1569449136';
                    appStoreUrl = 'https://babycode.org';
                    
                } else if (/Windows/i.test(userAgent)) {
                    // appStoreUrl = 'https://www.microsoft.com/store/apps/9nblggh4nns1';
                    // appStoreUrl = 'https://play.google.com/store/apps/details?id=babycode.ielts';
                    appStoreUrl = 'https://babycode.org';
                } else {
                    appStoreUrl = 'https://play.google.com/store/apps/details?id=babycode.ielts';
                }


           


                const res = await axios.post(
                    "https://ieltspro.babycode.org/clickOnAdvertisementLink/",
                    {
                        "uniqueDeviceId": deviceId ? deviceId : "Device Id is not available",
                        "couponCode": couponCode ? couponCode : "Coupon Id is not available",
                        "platform": getOS()
                    }, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                // // // Append the device ID as a query parameter to the App Store URL
                const trackedUrl = appStoreUrl;
                // // // Redirect the user to the tracked URL
                window.location.href = trackedUrl;

            } catch (err) {
                console.log(userAgent, ' got the error ', err)
                // In case of an error, use the same logic for redirecting to the App Store URL
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                var appStoreUrl = '';
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    appStoreUrl = 'https://apps.apple.com/us/app/babycode-ielts-preparation/id1569449136';
                } else if (/android/i.test(userAgent)) {
                    appStoreUrl = 'market://details?id=babycode.ielts&referrer=utm_source%3Dyoutube%26utm_medium%3Dvideo_description';
                } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
                    appStoreUrl = 'macappstores://apps.apple.com/app/babycode-ielts-preparation/id1569449136';
                } else if (/Windows/i.test(userAgent)) {
                    appStoreUrl = 'https://www.microsoft.com/store/apps/9nblggh4nns1';
                } else {
                    appStoreUrl = 'https://play.google.com/store/apps/details?id=babycode.ielts';
                }

                console.log(err);

                // Append the device ID as a query parameter to the App Store URL
                const trackedUrl = appStoreUrl;
                // Redirect the user to the tracked URL
                window.location.href = trackedUrl;
            }
        }

        redirectToAppStore();
    }, []);






   

}

export default Component